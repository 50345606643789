import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useTheme } from "@mui/material";

function SwipeableTextMobileStepper({ images, autoPlayInterval = 3000 }) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = images.length;

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
    }, autoPlayInterval);

    return () => clearInterval(interval);
  }, [activeStep, autoPlayInterval, maxSteps]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
  };

  const handleBack = () => {
    setActiveStep(
      (prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps
    );
  };

  return (
    <Box
      sx={{
        position: "relative",
        height: "100%",
        overflow: "hidden",
        borderRadius: { xs: "5vh", sm: "0vh" },
      }}
    >
      <Box
        sx={{
          position: "relative",
          height: "100%",
        }}
      >
        {images.map((image, index) => (
          <div
            key={index}
            style={{
              opacity: index === activeStep ? 1 : 0,
              transition: "opacity 1s ease-in-out",
              position: "absolute",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
            <div
              style={{
                position: "absolute",
                bottom: "10px",
                left: 0,
                right: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* Arrows positioned over the dots */}
              <Button onClick={handleBack} aria-label="Previous Image">
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight
                    sx={{ color: "#fff", fontSize: "3vh", m: 0 }}
                  />
                ) : (
                  <KeyboardArrowLeft
                    sx={{ color: "#fff", fontSize: "3vh", m: 0 }}
                  />
                )}
              </Button>
              {images.map((_, dotIndex) => (
                <span
                  key={dotIndex}
                  style={{
                    width: "1vh",
                    height: "1vh",
                    borderRadius: "50%",
                    background: dotIndex === activeStep ? "#fff" : "#999",
                    margin: "0 0.5vh", // Adjusted margin here
                  }}
                />
              ))}
              <Button
                onClick={handleNext}
                aria-label="Next Image"
                sx={{ p: 0 }}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft
                    sx={{ color: "#fff", fontSize: "3vh", m: 0 }}
                  />
                ) : (
                  <KeyboardArrowRight
                    sx={{ color: "#fff", fontSize: "3vh", m: 0 }}
                  />
                )}
              </Button>
            </div>
          </div>
        ))}
      </Box>
    </Box>
  );
}

export default SwipeableTextMobileStepper;
