import React from 'react';

const ArrowBack = () => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_b_88_68)">
      <circle cx="22" cy="22" r="22" transform="matrix(-1 0 0 1 44 0)" fill="black" fillOpacity="0.6" />
      <circle cx="22" cy="22" r="21.5" transform="matrix(-1 0 0 1 44 0)" stroke="white" strokeOpacity="0.2" />
    </g>
    <path
      d="M25.3253 27.9723C25.145 28.1516 24.9011 28.2522 24.6468 28.2522C24.3925 28.2522 24.1485 28.1516 23.9682 27.9723L18.6745 22.6786C18.4952 22.4982 18.3946 22.2543 18.3946 22C18.3946 21.7457 18.4952 21.5018 18.6745 21.3214L23.9682 16.0277C24.1485 15.8484 24.3925 15.7478 24.6468 15.7478C24.9011 15.7478 25.145 15.8484 25.3253 16.0277C25.5046 16.208 25.6052 16.452 25.6052 16.7063C25.6052 16.9605 25.5046 17.2045 25.3253 17.3848L20.715 22L25.3253 26.6152C25.5046 26.7955 25.6052 27.0395 25.6052 27.2938C25.6052 27.548 25.5046 27.792 25.3253 27.9723Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_b_88_68"
        x="-6"
        y="-6"
        width="56"
        height="56"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="3" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_88_68" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_88_68" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default ArrowBack;
