import React from 'react';

const ArrowForward = () => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_b_88_67)">
      <circle cx="22" cy="22" r="22" fill="black" fillOpacity="0.6" />
      <circle cx="22" cy="22" r="21.5" stroke="white" strokeOpacity="0.2" />
    </g>
    <path
      d="M18.6745 27.9723C18.8549 28.1516 19.0988 28.2522 19.3531 28.2522C19.6074 28.2522 19.8513 28.1516 20.0317 27.9723L25.3254 22.6786C25.5047 22.4982 25.6053 22.2543 25.6053 22C25.6053 21.7457 25.5047 21.5018 25.3254 21.3214L20.0317 16.0277C19.8513 15.8484 19.6074 15.7478 19.3531 15.7478C19.0988 15.7478 18.8549 15.8484 18.6745 16.0277C18.4953 16.208 18.3947 16.452 18.3947 16.7063C18.3947 16.9605 18.4953 17.2045 18.6745 17.3848L23.2849 22L18.6745 26.6152C18.4953 26.7955 18.3947 27.0395 18.3947 27.2938C18.3947 27.548 18.4953 27.792 18.6745 27.9723Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_b_88_67"
        x="-6"
        y="-6"
        width="56"
        height="56"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="3" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_88_67" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_88_67" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default ArrowForward;
